import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { loginService } from "../services/auth-services/loginService";
import { gaTrackEvent } from "../utils/analytics";
import { fetchBanks, fetchDataPlan, getWallet, purchaseData, verifyBank } from "../services/wallet";
import { useAuth } from "./AuthProvider";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [dataPlan, setDataPlan] = useState([]);
  const [purchaseDataLoading, setPurchaseDataLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [bankList, setBankList] = useState([]); 
  const [verifyForm, setVerifyForm] = useState({
    bank: '', account: '', amount: ""
  });
  const [payoutAcct, setPayoutAcct] = useState(null)
  const [error, setError] = useState("");
  const { auth } = useAuth();
  useEffect(() => {
    if(window.localStorage.getItem('token')){
      loadWallet()
      getPlan()
      getBanks()
    }
  }, [])


  const handlePayoutInputChange = (e) => {
    const { name, value } = e.target
    setVerifyForm(prev => ({
      ...prev,
      [name]: value
    }))
  }
  useEffect(()=>{
    if(verifyForm.account && verifyForm.bank && verifyForm.account > 5){
     verifyAccount(verifyForm)
    }
  }, [verifyForm])

  const loadWallet = async () => {
    const wallet = await getWallet()
    setWalletBalance(0)
  }

  const getPlan = async () => {
    const plans = await fetchDataPlan()
    setDataPlan(plans.dataplan)
  }

  const buyPlan = async (data) => {
    setPurchaseDataLoading(true)
    try {
      const plans = await purchaseData(data);
      toast.success(`Congratulations, Your purchase was succcessful!`);
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.errors[0] || `An error has occured. please try again later`);
    }
    setPurchaseDataLoading(false)
  }


  const getBanks = async () => {
    setBankLoading(true)
    try {
      const data = await fetchBanks();
      setBankList(data?.data)
    } catch (error) {
      toast.error(error.response?.data?.errors[0] || `An error has occured. please try again later`);
    }
    setBankLoading(false)
  }

  // const verifyBank = async (details) => {
  //   setBankLoading(true)
  //   try {
  //     const verify = await verifyBank(details);
  //     console.log(verify)
  //   } catch (error) {
  //     toast.error(error.response?.data?.errors[0] || `An error has occured. please try again later`);
  //   }
  //   setBankLoading(false)
  // }


  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  
  const verifyAccount = debounce(async(data) => {
    setBankLoading(true)
    const details = await verifyBank(data)
   if(details?.data?.account_name){
    setPayoutAcct({
      bank: verifyForm?.bank,
      account: details?.data?.account_number,
      account_name: details?.data?.account_name,
      amount: verifyForm.amount
    })
   }
    setBankLoading(false)
  }, 2000);

  return (
    <WalletContext.Provider
      value={{
        dataPlan,
        buyPlan,
        purchaseDataLoading,
        bankLoading, 
        getBanks,
        bankList,
        setVerifyForm,
        verifyForm,
        handlePayoutInputChange,
        payoutAcct,
        walletBalance
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
