import "./PromoModal.css";
import React from "react";

import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { gaTrackEvent } from "../../utils/analytics";

export const PromoModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setIsLoginModalOpen } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      // setIsModalOpen(true)
    }, 10000);
  }, [])


  return (
    isModalOpen &&
    <div className="promo-modal-container">
      <div className="promo-input-container">
        <div className="promo-content">

          <div className="promo-details">
            <p>🎉 Exclusive Offer for you</p>
            <h2>Claim ₦10,000 Free</h2>
            <p>Coupon when you signup now. Apply this coupon in your cart. Offer valid till Nov 30th </p>
            <br />
            <div className="download-btns"> 
            <button
              className="explore-btn"
              style={{marginRight: '.3em'}}
              onClick={() => {
                setIsModalOpen(false)
                setIsLoginModalOpen(true)
                window.fbq('track', 'Lead');
                navigate('/signup')
              // gaTrackEvent({label: 'playstore',type: 'download', category: 'download'})
            }}
            >
              <span>Sign up now!</span>
              {/* <svg width="24" height="23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 13 16"><path d="m6.848 7.641-6.785 7.03.001.004A1.825 1.825 0 0 0 1.834 16c.339 0 .657-.09.93-.246l.022-.013 7.637-4.301L6.848 7.64Z" fill="#EA4335"></path><path d="m13.713 6.444-.007-.004-3.297-1.866L6.694 7.8l3.728 3.638 3.28-1.847c.575-.303.965-.894.965-1.577 0-.677-.385-1.266-.954-1.57Z" fill="#FBBC04"></path><path d="M.062 1.33c-.04.146-.062.3-.062.46v12.42c0 .16.021.315.062.46l7.02-6.849L.061 1.33Z" fill="#4285F4"></path><path d="m6.898 8 3.512-3.427L2.78.256A1.871 1.871 0 0 0 1.834 0C.986 0 .27.563.063 1.327v.002L6.897 8Z" fill="#34A853"></path></svg> */}
            </button>
            {/* <button
              className="explore-btn"
              onClick={() => {
                setIsModalOpen(false)
                setIsLoginModalOpen(true)
                window.fbq('track', 'Lead');
              gaTrackEvent({label: 'appstore',type: 'download', category: 'download'})
            }}
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="mr-1.5" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5-34.9-50-87.7-77.5-157.3-82.8-65.9-5.2-138 38.4-164.4 38.4-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8 23.8 68.2 109.6 235.3 199.1 232.6 46.8-1.1 79.9-33.2 140.8-33.2 59.1 0 89.7 33.2 141.9 33.2 90.3-1.3 167.9-153.2 190.5-221.6-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7-44.8 2.6-96.6 30.5-126.1 64.8-32.5 36.8-51.6 82.3-47.5 133.6 48.4 3.7 92.6-21.2 129-63.7z"></path></svg>
              <span>Get it on AppleStore</span>

            </button> */}
          </div>
          </div>
          <div
            className="cross-tab-icon cross-tab-icon-mobile"
            onClick={() => setIsModalOpen(false)}
          >
            <RxCross2 color={"rgb(106, 106, 65)"} size={25} />
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
};
