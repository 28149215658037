import React from "react";
import { Home } from "../pages/Home/Home";
import { Cart } from "../pages/Cart/Cart";
import { Route, Routes } from "react-router-dom";
import { Login } from "../pages/auth/Login/Login";
import { ProductListing } from "../pages/ProductListing/ProductListing";
import { ProductDetails } from "../pages/ProductDetails/ProductDetails";
import { RequiresAuth, RequiresCartProducts } from "../components/requires-auth/RequiresAuth";
import { Signup } from "../pages/auth/Signup/Signup";
import { Logout } from "../pages/auth/Logout/Logout";
import { Checkout } from "../pages/Checkout/Checkout";
import { Wishlist } from "../pages/Wishlist/Wishlist";
import { UserProfile } from "../pages/UserProfile/UserProfile";
import { Profile } from "../pages/UserProfile/Profile/Profile"; 
import { Addresses } from "../pages/UserProfile/Addresses/Addresses";
import { Orders } from "../pages/UserProfile/Orders/Orders";
import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import { ReturnPolicy } from "../pages/ReturnPolicy/ReturnPolicy";
import { PrivacyPolicy } from "../pages/PrivacyPolicy/PrivacyPolicy";
import { Termsofuse } from "../pages/Termsofuse/Termsofuse";
import { Contactus } from "../pages/Contactus/Contactus";
import { Swap } from "../pages/Swap/Swap";
import SwapSuccess from "../pages/Swap/Success";
import { Upload } from "../pages/upload/Upload";
import { ShippingPolicy } from "../pages/ShippingPolicy/PrivacyPolicy";
import Payment from "../pages/Checkout/Payment";
import BuyData from "../pages/UserProfile/Profile/BuyData";

export const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/return-policy" element={<ReturnPolicy />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/shipping-policy" element={<ShippingPolicy />} />
      <Route path="/terms" element={<Termsofuse />} />
      <Route path="/contact" element={<Contactus />} />
      <Route path="/swap" element={<Swap />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/request-success" element={<SwapSuccess />} />
      <Route path="/load-items" element={<Upload />} />
      
      <Route
        path="/cart"
        element={
          <RequiresAuth>
            <Cart />
          </RequiresAuth>
        }
      />
      <Route
        path="/wishlist"
        element={
          <RequiresAuth>
            <Wishlist />
          </RequiresAuth>
        }
      />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/product-listing" element={<ProductListing />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/product-details/:productId" element={<ProductDetails />} />
      <Route
        path="/checkout"
        element={
          <RequiresAuth>
            <RequiresCartProducts>
              <Checkout />
            </RequiresCartProducts>
          </RequiresAuth>
        }
      />
      <Route path="/profile" element={<UserProfile />}>
        <Route
          path="/profile/"
          element={
            <RequiresAuth>
              <BuyData />
            </RequiresAuth>
          }
        />
        <Route path="/profile/orders" element={<Orders />} />
        <Route path="/profile/addresses" element={<Addresses />} />
        <Route path="/profile/buy-data" element={<BuyData />} />
      </Route>
    </Routes>
  );
};
