import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { useData } from "../../contexts/DataProvider.js";
import { useState } from "react";

import "./UserProfile.css";
import { useAuth } from "../../contexts/AuthProvider.js";
import { AddFundModal } from "../../components/AddFundModal.jsx/AddFundModal.jsx";
import { WithDrawFundsModal } from "../../components/AddFundModal.jsx/WithdrawFundsModal.jsx";
import { numberWithCommas } from "../../utils/index.js";
import { useWallet } from "../../contexts/WalletProvider.js";

export const UserProfile = () => {
  const { loading } = useData();
  const { currentPage, setCurrentPage,  } = useAuth();
  const {walletBalance} = useWallet()
  const [isAddFundModalOpen, setAddFundModalOpen] = useState(false);
  const [isWithdrawFundModalOpen, setWithdrawFundModalOpen] = useState(false);

  useEffect(()=>{
    if(window.location.pathname == '/profile/orders') setCurrentPage('orders'); else if(window.location.pathname == '/profile')
      setCurrentPage('profile')
  }, [])

   
  return (
    !loading && (
          <div className="x-wallet-dashboard ">
     

      <div className="wallet-cards">
        <div className="wallet-card wallet-card--main">
          <h2 className="wallet-card__title">Wallet Balance</h2>
          <p className="wallet-card__balance">₦{numberWithCommas(walletBalance)}</p>
          <div className="wallet-card__actions">
            <button className="button button--blue" onClick={()=>{
              // setAddFundModalOpen(true)
              alert('Coming soon')
              }}>Add Funds</button>
            <button className="button button--purple" onClick={()=>{
              alert('Coming soon')
              // setWithdrawFundModalOpen(true)
            }}>Withdraw to Bank</button>
          </div>
        </div>
        
      </div>
   
        {/* <div className="user-profile-container">
          <div className="link-container">
            <Link
              style={{ color: currentPage === "profile" ? "black" : "grey" }}
              onClick={() => setCurrentPage("profile")}
              to="/profile"
            >
              Profile
            </Link>
            <Link
              style={{ color: currentPage === "orders" ? "black" : "grey" }}
              onClick={() => setCurrentPage("orders")}
              to="/profile/orders"
            >
              Orders
            </Link>
            <Link
              style={{ color: currentPage === "addresses" ? "black" : "grey" }}
              onClick={() => setCurrentPage("addresses")}
              to="/profile/addresses"
            >
              Addresses
            </Link>
          </div>
          <Outlet />
        </div> */}
          <Outlet />
          <AddFundModal isModalOpen={isAddFundModalOpen} setIsModalOpen={setAddFundModalOpen} />
          <WithDrawFundsModal isModalOpen={isWithdrawFundModalOpen} setIsModalOpen={setWithdrawFundModalOpen} />
          </div>
    )
  );
};
