import "./AddFundModal.css";
import React from "react";

import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { gaTrackEvent } from "../../utils/analytics";
import { RiFileCopyFill } from "react-icons/ri";
import { useWallet } from "../../contexts/WalletProvider";
import { numberWithCommas } from "../../utils";

export const WithDrawFundsModal = ({ isModalOpen, setIsModalOpen }) => {

  const {   bankLoading, bankList,
    handlePayoutInputChange,
    verifyForm,payoutAcct,
    walletBalance,
    getBanks } = useWallet();
  const navigate = useNavigate() 
  const handleCopy = async (text) => {
    navigator.clipboard.writeText(text);
    // setCopiedText(text);
    // setTimeout(() => setCopiedText(''), 2000);
  };
  
  return (
    isModalOpen &&
    <div className="funding-modal-container">
      <div className="funding-input-container">
        <div className="funding-content">

          <div className="funding-details">
            <h2>Fund Wallet</h2>
            {/* <p>Bank Transfer</p> */}

            <form className="form-container">
            <div className="form-group">
                <label htmlFor="phone">
                  Amount <span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  value={verifyForm.amount}
                  onChange={handlePayoutInputChange}
                  placeholder='Please enter the amount'
                  required
                  max={walletBalance}
                  min={walletBalance}
                />
              <small>Balance: ₦{numberWithCommas(walletBalance)}. Withdrawal fee ₦100</small>
              </div>
            <div className="form-group">
                <label htmlFor="phone">
                  Select Bank <span className="required">*</span>
                </label>
                <select
                  type="text"
                  id="phone"
                  name="bank"
                  onChange={handlePayoutInputChange}
                  value={verifyForm.bank} 
                  required
                >
                  <option >Please select a bank</option>
                  {bankList.map(d => <option value={d.code}>{d.name}</option> 
                  )}
                </select>

              </div>
              <div className="form-group">
                <label htmlFor="phone">
                  Account Number <span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  name="account"
                  value={verifyForm.account} 
                  onChange={handlePayoutInputChange}
                  // placeholder='Please enter the mobile number'
                  required
                />
            <small> {payoutAcct?.account_name} </small>
            </div>
            <div className="download-btns">
              <button
                className="explore-btn"
                type="submit"
                disabled={payoutAcct?.account_name ? false : true}
                style={{ marginRight: '.3em' }}
                // onClick={() => window.location.reload()}
              >
                <span>Continue</span>
              </button>
            </div>
            </form>
            <br />
          
          </div>
          <div
            className="cross-tab-icon cross-tab-icon-mobile"
            onClick={() => setIsModalOpen(false)}
          >
            <RxCross2 color={"rgb(106, 106, 65)"} size={25} />
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  );
};
