import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer/Footer";
import AxiosCall from "../../controller/axios";
import toast from "react-hot-toast";
import { useData } from "../../contexts/DataProvider";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { updateProduct } from "../../services/products-services";
export const Upload = () => {
  const { loading, getProduct } = useData();
  const { state } = useData();

  const [formData, setFormData] = useState({
    title: "",
    original_price: "",
    discounted_price: "",
    rating: 0,
    reviews: 0,
    description: "",
    images: [],
    size: [],
    vendorId: "6693ba1b84e5e9986703fc5a",
    categoryId: "",
    is_stock: 3,
  });
  const [uploadingImages, setUploadingImages] = useState(false);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState('');
  const [categories, setCategories] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // Access the 'token' query parameter
  const productId = queryParams.get('productId'); // Access the 'token' query parameter
  useEffect(() => {
    getCategories()
    try {
      if (token) {
        const data = jwtDecode(token)
        getProduct({ _id: productId })
      }
    } catch (error) {

    }
  }, [])

  useEffect(()=>{
    if(state.product?._id){
      setFormData(state.product)
    }
  }, [state.product])

  const getCategories = async () => {
    var categories = await AxiosCall({
      method: "get",
      path: 'api/categories'
    })
    setCategories(categories.data.docs)

  }
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };


  const handleImageUpload = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    setUploadingImages(true);

    // Add all files to the form data for upload
    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
      // formData.append("upload_preset", "your-upload-preset"); // For Cloudinary
    }

    try {
      // Upload images to Cloudinary (or your API)
      const response = await AxiosCall({
        method: 'post',
        data: formData,
        path: 'api/products/pushimgs'
      })

      const images = response.data.images;

      setFormData((prevData) => ({
        ...prevData,
        images: [...prevData.images, ...images],
      }));

      setUploadingImages(false);
    } catch (error) {
      console.log("Image upload failed:", error);
      setUploadingImages(false);
    }
  };

  const handleSizeChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSizes = [...formData.size];
    updatedSizes[index][name] = value;
    setFormData((prevData) => ({
      ...prevData,
      size: updatedSizes,
    }));
  };

  const addSizeField = () => {
    setFormData((prevData) => ({
      ...prevData,
      size: [
        ...prevData.size,
        {
          title: "",
          is_stock: 0,
        },
      ],
    }));
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    try {

      if(token){
        setUploading(true)
        await updateProduct({is_stock: 0, _id : state?.product?._id})
        window.location.reload()
      }
    }catch (error) {
      setUploading(false)
      toast.error("Error uploading product " + error?.response?.data?.errors[0]);
  }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      if(token && state?.product?._id){
        setUploading(true)
        await updateProduct({...formData, _id : state?.product?._id})
        window.location.reload()
      }else {

      setUploading(true)
      var is_stock = formData.size.length ? 0 : formData.is_stock
      var rating = (Math.random() * 0.5 + 4.5).toFixed(1)
      var reviews = Math.floor(Math.random() * 11) + 5;
      formData.size.map(s => {
        is_stock += parseInt(s.is_stock)
      })
      const res = await AxiosCall({
        method: 'post',
        path: "api/products",
        data: { ...formData, is_stock, rating, reviews }
      })

      console.log("Product uploaded successfully:", res);
      setUploading(false)
      setFormData({
        title: "",
        original_price: "",
        discounted_price: "",
        rating: 0,
        reviews: 0,
        description: "",
        images: [],
        size: [],
        vendorId: "66927af6eb322a27f7c6902c",
        categoryId: formData.categoryId,
        is_stock: 3,
      })
      toast.success(`Uploaded successfully :)`);

      setUploading(false)
    }
  } catch (error) {
      setUploading(false)
      console.log("Error uploading product:", error);
      toast.error("Error uploading product " + error?.response?.data?.errors[0]);
  }
};

  return (
    <>
      <div className="swap-container">
        <div className="policy-container">
          <div className="policy-heading">
            {state?.product?._id && token ?<h2>Edit product</h2> : <h2>Upload product</h2>}
            {/* <h3>Upload gadg: Upgrade with Ease.</h3> */}
          </div>
          <div className="policy-desc">
            <form onSubmit={handleSubmit} >
              <div className="name-container">
                <label>Title:</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="name-container">
                <label>Original Price:</label>
                <input
                  type="number"
                  name="original_price"
                  value={formData.original_price}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="name-container">
                <label>Discounted Price:</label>
                <input
                  type="number"
                  name="discounted_price"
                  value={formData.discounted_price}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="name-container">
                <label>Description:</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="name-container">
                <label>Images:</label>
                <input type="file" multiple onChange={handleImageUpload} />
                {uploadingImages && <p>Uploading images...</p>}
                <ul>
                  {formData.images.map((image, index) => (
                    <li key={index}>
                      <img src={image} alt="Uploaded" width="100" />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="name-container">
                <label>Category:</label>
                <select name="categoryId" onChange={handleInputChange} value={state?.product?.categoryId?._id}>
                  <option >Please select a category</option>
                  {categories.map(cat => (
                    <option value={cat._id}>{cat.title}</option>
                  ))}
                </select>
                {/* <input
          type="text"
          name="categoryId"
          value={formData.categoryId}
          onChange={handleInputChange}
          required
        /> */}
              </div>
              <div className="name-container">
                <label>Stock Availability:</label>
                <input
                  type="number"
                  name="is_stock"
                  value={formData.is_stock}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="name-container">
                <h3>Sizes:</h3>
                {formData.size.map((size, index) => (
                  <div key={index} className="name-container" style={{ padding: '1em 2em', border: '1px solid #eee', margin: '0 0 1em 0' }}>
                    <label>Title:</label>
                    <input
                      type="text"
                      name="title"
                      value={size.title}
                      onChange={(e) => handleSizeChange(index, e)}
                      required
                    />
                    <label>In Stock:</label>
                    <input
                      type="number"
                      name="is_stock"
                      value={size.is_stock}
                      onChange={(e) => handleSizeChange(index, e)}
                      required
                    />
                  </div>
                ))}
                <button type="button" onClick={addSizeField}>
                  Add Size
                </button>
              </div>
              <br />
              <button type="submit" disabled={uploading} className="explore-btn">{token && state?.product?._id ? "Save" : "Upload product"} </button>
              <br />
              <br />
           {token && state?.product?._id && <button onClick={handleDelete} disabled={uploading} className="explore-btn">Out of stock </button>}

            </form>
          </div>

        </div >

      </div >
      <Footer />
    </>
  );
};
